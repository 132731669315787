import SupplierPage from 'components/pages/supplier/SupplierPage';
import Seo from 'components/Seo';
import { graphql } from 'gatsby';
import * as React from 'react';

const Index = () => (
	<div>
		<Seo
			title='Zamień faktury na gotówkę | FINTEQ'
			keywords='wypłać fakturę, bez ryzyka, skróć termin płatności, pieniądze bez formalności, finansowanie dla firm, szybka wypłata gotówki'
			description='Błyskawicznie wypłacaj faktury przed terminem płatności i samodzielnie decyduj kiedy i ile chcesz wypłacić.'
			lang='pl-PL'
			locale='pl_PL'
		/>

		<SupplierPage />
	</div>
);

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
